* {
  box-sizing: border-box !important;
}

body {
  background-color: #555;
}

.App{
  display: flex;
}

.ttt{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100vh;
  border: 5px solid #999;
}

.clickable{
  width: 33vh;
  height: 33vh;
  background-color: #333;
  border: 5px solid #999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable--clicked{
  cursor: not-allowed;
}

.clickable__totem {
  width: 70%;
  height: 70%;
}

.clickable__totem--circle{
  border-radius: 50%;
  border: 20px solid #999;
}

.clickable__totem--cross{
  position: relative;
}

.clickable__totem--cross:before, .clickable__totem--cross:after{
  content: '';
  position: absolute;
  border-radius: 15px;
  height: 100%;
  width: 0;
  border: 10px solid #999;
  transform-origin: center center;
}

.clickable__totem--cross:before{
  transform: rotate(45deg) translateX(-50%);
  left: 50%;
}


.clickable__totem--cross:after{
  transform: rotate(-45deg) translateX(50%);
  right: 50%;
}

.meta {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
